div.Home-Container > div.Home-Box {
    position: relative;
    margin-bottom: 40px;
}

.Home-JobChoiceGroup label.ms-ChoiceField-field::before {
    display: none;
}

div.Home-Container {
    margin: 0 auto;
}

@media (max-width: 760px) {
    .Home-JobStack {
        margin: 0 !important;
    }
}

@media (min-width: 760px) {
    div.Home-Container {
        max-width: 500px;
        min-height: calc(95vh - 160px);
        display: flex;
        flex-flow: column;
        justify-content: center;
        margin: 40px auto 0;
    }

    div.Home-Container > div.Home-Box {
        border: 1px solid #8383831A;
        /*box-shadow: #b1b1dc 0 7px 10px -10px, #dcdce5 0 11px 35px -23px, #f9f9f9 0 0 331px 216px;*/
        box-shadow: #b1b1b1 0 7px 10px -10px;
        /*#adadad1f -172px 12px 189px -20px, #c1c1c11e -252px 12px 119px -10px;*/
    }
}
