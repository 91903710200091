:root, html, body, div.AppRoot {
    margin: 0;
    padding: 0;
    user-select: none;
    font-family: "Segoe UI", sans-serif;
}

:root {
    --ck-z-default: 9999999 !important;
    --ck-color-base-foreground: #fff !important;
    --ck-color-toolbar-border: #fff0 !important;
    --ck-color-base-border: #fff0 !important;
    --ck-inner-shadow: #fff0 !important;
}

.ck-insert-table-dropdown-grid-box {
    --ck-color-base-border: #7774 !important;
}

body {
    -webkit-touch-callout: none;
}
