.ITPEventFullView {
    color: #000;
    user-select: text;
    /*max-width: 500px;*/
    /*margin: 0 auto;*/
}

.ITPEventFullView b {
    font-weight: 600;
}

.ITPEventFullView-h, .ITPEventFullView-b {
    width: 100%;
}

.ITPEventFullView-h, .ITPEventFullView-h td {
    border-collapse: separate;
    border: 1px solid #0005;
}

.ITPEventFullView-h td {
    text-align: center;
    padding: 0.75em;
}

.ITPEventFullView-b, .ITPEventFullView-b td:first-child {
    min-width: 25ch;
}

.ITPEventFullView-b, .ITPEventFullView-b td {
    border-collapse: collapse;
    border: 1px solid #0005;
    vertical-align: top;
}

.ITPEventFullView td {
    padding: 0.25em 0.5em;
}

.ITPEventFullView-b td {
    max-width: 43ch;
}
