.Base8s5gf76h .OverTopBar, .Base8s5gf76h .OverTopBarSpacer, .Base8s5gf76h .TopBar, .MainContainer {
    display: flex;
    flex-flow: row;
}

.Base8s5gf76h .OverTopBar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.Base8s5gf76h .OverTopBar, .Base8s5gf76h .OverTopBarSpacer {
    padding: 3px 12px;
    line-height: 20px;
}

.Base8s5gf76h .TopBar {
    padding: 8px 24px;
    line-height: 40px;
}

.Base8s5gf76h .OverTopBar > *, .Base8s5gf76h .TopBar > * {
    flex-grow: 1
}

.Base8s5gf76h .OverTopBar > *:last-child, .Base8s5gf76h .TopBar > *:last-child {
    text-align: right;
}

.Base8s5gf76h .Nav {
    min-width: 200px;
    border-right: 0 none;
    border-bottom: 1px solid #ddf;
    box-shadow: 0 -17px 10px -20px #ddf inset;
}

.Base8s5gf76h .Main {
    flex-grow: 1;
    padding: 0 0 20px 0;
    /*font-size: 30px;*/
    /*font-weight: 100;*/
}

.Base8s5gf76h .MainPre2 {
    display: flex;
    flex-flow: row;
    overflowX: auto;
}

.Base8s5gf76h .Side {
    /*margin: 20px 0;*/
    padding: 20px;
    min-width: 200px;
    min-height: 100px;

    margin: 20px 0 20px 20px;
    width: 200px;
}

.Base8s5gf76h .NavBtn {
    padding: 0.5em 1em;
    cursor: pointer;
}

.Base8s5gf76h .NavItems {
    padding: 0.75em 0;
}

.Base8s5gf76h .NavItems a {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 0.5em 1em;
    text-decoration: none;
}

.Base8s5gf76h .NavItems.collapsed {
    display: none;
}

.Base8s5gf76h > footer {
    display: flex;
    flex-flow: row wrap;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 0.95em;
    align-items: center;
    padding: 1px 2px 0;
}


/******** BREAKPOINTS (mobile first) ******/

.Base8s5gf76h .MainContainer {
    flex-flow: column nowrap;
}

.Base8s5gf76h .Nav .NavItem {
    display: none;
}

.Base8s5gf76h .Nav {
    display: none; /* Actually, yes, just no nav on mobile. */
}

.OverTopBarUser-company {
    display: none;
}

@media (min-width: 760px) {
    .OverTopBarUser-company {
        display: inline;
    }

    .Base8s5gf76h .Nav {
        display: block;
    }

    .Base8s5gf76h .NavBtn {
        display: none;
    }

    .Base8s5gf76h .MainContainer {
        flex-flow: row nowrap;
    }

    .Base8s5gf76h .Main {
        width: 1px;
        min-height: 50vh;
        flex-grow: 1;
    }

    .Base8s5gf76h .Side {
        max-width: 400px;
        margin: 20px 0 20px 20px;
        width: 30%;
    }

    .Base8s5gf76h .NavItems.collapsed {
        display: block;
    }

    .Base8s5gf76h .Nav .NavItem {
        display: block;
    }

    .Base8s5gf76h .Nav {
        border-bottom: 0 none;
        /*border-right: 1px solid #ddf;*/
        /*box-shadow: 0 -60px 30px -30px #fff inset, -10px 0 1px -10px #ddd inset, -9px 0 20px -20px #ddd inset;*/
        box-shadow: 0 -60px 30px -30px #7770 inset, -10px 0 1px -10px #777a inset, -9px 0 20px -20px #777a inset, -60px 106px 45px -96px #7778 inset;
        /*box-shadow: -17px 0 10px -20px #ddf inset;*/
    }
}

@media print {
    .Base8s5gf76h .Nav {
        display: none;
    }
}
