.EcoOdsExpensesTable-DetailsList *,
.EcoOdsExpensesTable-DetailsList {
    overflow-y: visible !important;
}

.EcoOdsExpensesTable-DetailsList .ms-DetailsHeader,
.EcoOdsExpensesTable-DetailsList [role='columnheader'] {
    height: 50px;
}

.EcoOdsExpensesTable-DetailsList [role='columnheader'] [role='button'],
.EcoOdsExpensesTable-DetailsList [role='columnheader'] [role='button'] > * {
    overflow: visible;
}

.EcoOdsExpensesTable-DetailsList [role='columnheader'] > * {
    display: flex;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-end;
}

.EcoOdsExpensesTable-DetailsList [role='rowheader'],
.EcoOdsExpensesTable-DetailsList [role='gridcell'] {
    /*text-align: right;*/
}

.EcoOdsExpensesTable-DetailsList [role='gridcell'] {
    /*font-family: monospace;*/
    display: flex;
    /*text-align: right;*/
    /*justify-content: flex-end;*/
    justify-content: flex-start;
    align-items: center;
}
