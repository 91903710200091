.NotificationItem {
    align-items: center;
    margin-right: -8px;
}

.NotificationItem:hover {
    background: #f3f2f1;
    box-shadow: -40px 0 0 4px #f3f2f1, 40px 0 0 4px #f3f2f1;
    cursor: pointer;
}

.NotificationItem.seen {
    opacity: 0.75;
}

.NotificationItem.notSeen .NotificationItem-markAsRead {
    opacity: 0.75;
}

.NotificationItem:hover .NotificationItem-markAsRead {
    opacity: 1;
}

.NotificationItem.notSeen .NotificationItem-markAsRead:hover {
    color: darkred;
}
