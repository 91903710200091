.ITPStatsTable {
    border-collapse: collapse;
    width: 100%;
    box-sizing: border-box;
    border-top: 0 none;
}

.ITPStatsTable.itp_stats_allow_user_selection {
    user-select: text;
}

.ITPStatsTable.itp_stats_table_max_vw_fix {
    max-width: max(800px, calc(100vw - 200px));
}

.ITPStatsTable td {
    padding: 0 0.25em;
}

.ITPStatsTable td {
    border: 1px solid #7772;
    border-bottom: 0 none;
    border-top: 0 none;
    text-align: center;
    vertical-align: baseline;
}

.ITPStatsTable td.al {
    text-align: left;
    padding-left: 1vw;
    padding-right: 1vw;
}

.ITPStatsTable td.itp_stats_title_max_vw_fix {
    max-width: max(400px, calc(100vw - 700px));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ITPStatsTable td:first-child {
    border-left: 0 none;
}

.ITPStatsTable td:last-child {
    border-right: 0 none;
}

.ITPStatsTable thead td {
    font-weight: 500;
    border-top: 0 none;
}

.ITPStatsTable tbody tr:first-child td {
    /*border-top: 3px double #0006;*/
    /*border-top: 1px solid #1a6fed77;*/
    border-top: 1px solid #7772;
}

.ITPStatsTable tr:first-child td {
    padding-top: 0.33em;
}

.ITPStatsTable tr:last-child td {
    padding-bottom: 0.33em;
}

.ITPStatsTable tbody tr:hover {
    background: #7771;
}

.ITPStatsTable tbody tr.preHighlight {
    background: #1E1EA211;
}

.ITPStatsTable.selectRow tbody tr:hover {
    box-shadow: 0 0 0 0.02cm #4c6cbd;
    cursor: pointer;
}

.ITPStatsTable:not(.noHides) tbody tr:not(:first-child):not(:hover) td:first-child {
    visibility: hidden;
}
